<template><div><h1 id="updating-custom-firmware" tabindex="-1"><a class="header-anchor" href="#updating-custom-firmware"><span>Updating Custom Firmware</span></a></h1>
<div class="custom-container tip"><p class="custom-container-title">NOTE</p>
<p>The script does not work on Windows. If you are using Windows, boot a live Linux iso, such as Ubuntu or Fedora, and run the script from there.</p>
</div>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>curl is not installed by default for Ubuntu or Ubuntu-based distros. To install it, run: <code v-pre>sudo apt update &amp;&amp; sudo apt install -y curl</code></p>
</div>
<ol>
<li>Run <a href="https://mrchromebox.tech/#fwscript" target="_blank" rel="noopener noreferrer">MrChromebox's Firmware Utility Script</a>.
<ul>
<li>In case you forgot, type <code v-pre>cd; curl -LO mrchromebox.tech/firmware-util.sh &amp;&amp; sudo bash firmware-util.sh</code> and press Enter.</li>
</ul>
</li>
<li>Choose option 1: <code v-pre>Install/Update UEFI (Full ROM) Firmware</code>, and reboot if successful.</li>
</ol>
<div class="custom-container danger"><p class="custom-container-title">DANGER</p>
<p><strong>DO NOT REBOOT if flash was not successful</strong></p>
</div>
</div></template>


